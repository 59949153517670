<template>
    <div class="pl-my-quote-wrap">
       <div class="module-title">
           我的报价
       </div>
       <div class="pl-my-quote-search-box">
           <span>选择时间:</span>
           <el-date-picker
           class="pl-time"
            v-model="searchParam.start_at"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="起始日期">
            </el-date-picker>
            -
            <el-date-picker
            class="pl-time"
            v-model="searchParam.end_at"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
            </el-date-picker>
            <span>报价单号：</span>
            <el-input placeholder="请输入单号" class="pl-input" v-model="searchParam.sn"></el-input>
            <el-button type="primary" @click="search" :loading="loading">搜索</el-button>
       </div>
       <div class="pl-my-quote-body-box">
           <el-table :data="tableData" border>
                <el-table-column
                width="200"
                align="center"
                header-align="center"
                prop="sn"
                label="报价单号">
                </el-table-column>
                <el-table-column
                align="center"
                header-align="center"
                prop="name"
                label="填单人">
                <template slot-scope="scope">
                    <span>{{scope.row.user.name}}</span>
                </template>
                </el-table-column>
                <el-table-column
                width="200"
                align="center"
                header-align="center"
                prop="created_at"
                label="提交日期">
                </el-table-column>
                <el-table-column
                align="center"
                header-align="center"
                prop="total_amount"
                label="金额">
                </el-table-column>
                <el-table-column
                align="center"
                header-align="center"
                label="状态">
                <template slot-scope="scope">
                    <span>{{scope.row.status == 0 ? '报价中' :'报价完成'}}</span>
                </template>
                </el-table-column>
                <el-table-column
                align="center"
                header-align="center"
                label="操作">
                <template slot-scope="scope">
                    <span class="pointer" @click="goPage(scope)">详情</span>
                </template>
                </el-table-column>
           </el-table>
           <div class="pl-pagination">
           <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pages.per_page"
            :total="pages.total"
            @current-change="changePage">
            </el-pagination>
            </div>
       </div>
    </div>
</template>
<script>
import {getQuotationList} from '@/api/qoute.js'
export default {
    name: 'myquote',
    data() {
        return {
            loading:false,
            searchParam: {
                start_at: '',
                end_at:'',
                sn: ''
            },
            pages: {
                per_page: 20,
                page:1,
                total:0
            },
            tableData: []
        }
    },
    methods: {
        goPage({row}) {
            if(!row.id) return false
            this.$router.push('/user/quote/detail/'+row.id)
        },
        search() {
            this.loading = true
            this.getQuotationList()
        },
        getQuotationList() {
            getQuotationList({...this.pages,...this.searchParam}).then(res => {
                this.loading = false
                this.tableData = res
            })
        },
        changePage(v) {
            this.pages.page = v
            this.getQuotationList()
        },
    },
    created() {
        this.getQuotationList()
    }
}
</script>
<style lang="scss" scoped>
.pl-my-quote-wrap {
    width: 920px;
    .pl-my-quote-search-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
            padding: 0 5px;
        }
        .pl-time {
            width: 150px;
        }
        .pl-input {
            width: 200px;
            margin: 0 10px;
        } 
    }
    .pl-my-quote-body-box {
        margin-top: 45px;
    }
    
}
</style>